import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/index.vue')
  },

  {
    path: '/login',
    component: () => import('@/views/auth/login.vue')
  },
  {
    path: '/register',
    component: () => import('@/views/auth/register.vue')
  },
  {
    path: '/home/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/home/trips'
      },
      {
        path: 'trips',
        component: () => import('@/views/trips/index.vue')
      },
      {
        path: 'trips/:id',
        component: () => import('@/views/trips/view.vue')
      },
      {
        path: 'settings',
        component: () => import('@/views/settings.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
